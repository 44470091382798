/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import { Container, Stack, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useQuery } from "@tanstack/react-query";

import { Layout } from "..";
import type { ILayoutProps } from "..";

import { buildQueryFn } from "src/utilities/buildQueryFn";
import { AlertProvider } from "src/contexts/Alert";
import { buildPathFromQuery } from "src/utilities/buildPathFromQuery";
import usePortalConfig from "src/hooks/usePortalConfig";

interface IPublicLayoutProps extends ILayoutProps {
  titleProps?: Record<
    string,
    string | number | boolean | Date | null | undefined
  >;
  subtitle?: string;
}

export function PublicLayout({
  children,
  title,
  titleProps = {},
  subtitle,
}: IPublicLayoutProps): JSX.Element | null {
  const t = useTranslations();
  const localizedTitle = t(title, titleProps);
  const router = useRouter();

  const { data: portalConfig, isFetched } = usePortalConfig();

  const { data: signedIn } = useQuery<boolean>(["/api/isSignedIn"], {
    queryFn: buildQueryFn({ isPageRoute: true }),
  });

  const [logoUrl, setLogoUrl] = useState<string>("");

  useEffect(() => {
    if (signedIn === true) {
      let destination = "/dashboard?redirectReason=auth";

      const resourcePath = buildPathFromQuery(router.query);

      if (resourcePath && resourcePath.path.length > 0) {
        const { href, path } = resourcePath;

        destination = href ? `${href}?${path}` : `/dashboard?${path}`;
      }

      router.replace(destination);
    }
  }, [signedIn]);

  useEffect(() => {
    if (isFetched) {
      if (portalConfig && portalConfig?.logoUrls?.logo) {
        setLogoUrl(portalConfig?.logoUrls?.logo);
      } else {
        setLogoUrl("/SVG/till_icon.svg");
      }
    }
  }, [portalConfig, isFetched]);

  return (
    <Layout title={localizedTitle}>
      <Container data-testid="layout-public" sx={{ pb: 1 }}>
        <Stack direction="column" spacing={8} justifyContent="center">
          <Container
            maxWidth="md"
            sx={{
              position: "relative",
              justifyContent: "center",
              paddingTop: 12,
              textAlign: "center",
              px: { xs: 0, lg: undefined },
            }}
          >
            <AlertProvider>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                {logoUrl && isFetched && (
                  <Box
                    sx={{
                      height: "42px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Image
                      src={logoUrl}
                      alt="Logo"
                      fill
                      style={{ objectFit: "contain" }}
                    />
                  </Box>
                )}
                <Stack spacing={1}>
                  <Typography variant="h1">{localizedTitle}</Typography>
                  {subtitle && (
                    <Typography variant="subtitle1" component="h2">
                      {t(subtitle)}
                    </Typography>
                  )}
                </Stack>
                {children}
              </Stack>
            </AlertProvider>
          </Container>
        </Stack>
      </Container>
    </Layout>
  );
}

export default PublicLayout;
